import PropTypes from "prop-types";

export function DemystLogo({ fill = "#FFFFFF", width = 190, height = 47, className, id }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      id={id}
    >
      <path
        d="M53.147 32.1449C59.863 32.1449 63.9043 28.2988 63.9043 21.7769C63.9043 15.255 59.8571 11.4089 53.147 11.4089H46.0146V32.1449H53.147ZM52.9358 7.76864C62.3616 7.76864 67.9456 12.9967 67.9456 21.7769C67.9456 30.5571 62.3558 35.7852 52.9358 35.7852H44.2198C42.8883 35.7852 42.0495 34.9912 42.0495 33.6563V9.89752C42.0495 8.55668 42.8824 7.76276 44.2198 7.76276H52.9358V7.76864Z"
        fill={fill}
      />
      <path
        d="M75.2422 23.9469H88.5861C87.8764 20.7713 85.3718 18.6777 81.9112 18.6777C78.4565 18.6777 75.9519 20.7713 75.2422 23.9469ZM92.1347 24.9114C92.1347 26.3758 91.2608 27.2108 89.8003 27.2108H75.1659C75.7525 30.6394 78.3333 32.8976 82.1282 32.8976C84.5859 32.8976 86.5097 31.9332 87.718 30.5159C88.0934 30.0983 88.5509 29.8455 89.1375 29.8455C90.1815 29.8455 90.8912 30.5571 90.8912 31.5627C90.8912 31.9391 90.7681 32.3154 90.5159 32.6507C88.6389 34.9089 85.6768 36.2909 82.1341 36.2909C75.6293 36.2909 71.3299 32.0684 71.3299 25.7935C71.3299 19.5598 75.6234 15.2962 81.923 15.2962C87.8001 15.2962 92.1406 19.4775 92.1406 24.8291V24.9114H92.1347Z"
        fill={fill}
      />
      <path
        d="M126.706 24.1998V34.0679C126.706 35.2794 126.037 36.0322 124.911 36.0322C123.744 36.0322 123.075 35.2794 123.075 34.0679V24.6174C123.075 20.7301 121.45 18.6777 118.365 18.6777C115.157 18.6777 113.192 21.1888 113.192 25.2878V34.0679C113.192 35.2794 112.523 36.0322 111.356 36.0322C110.271 36.0322 109.561 35.2794 109.561 34.0679V24.6174C109.561 20.7301 107.936 18.6777 104.81 18.6777C101.602 18.6777 99.6366 21.1888 99.6366 25.2878V34.0679C99.6366 35.2794 98.968 36.0322 97.8008 36.0322C96.6746 36.0322 96.0059 35.2794 96.0059 34.0679V17.5485C96.0059 16.2959 96.6746 15.5432 97.8008 15.5432C98.968 15.5432 99.6366 16.2959 99.6366 17.5485V18.0484H99.7188C101.009 16.2489 103.097 15.2903 105.637 15.2903C108.599 15.2903 110.728 16.5017 111.895 18.76H111.978C113.356 16.5429 115.731 15.2903 119.028 15.2903C124.16 15.2962 126.706 18.3836 126.706 24.1998Z"
        fill={fill}
      />
      <path
        d="M150.132 17.5486V34.1503C150.132 40.337 146.337 44.0596 140.126 44.0596C136.413 44.0596 133.369 43.0128 131.703 40.8369C131.492 40.5428 131.328 40.1253 131.328 39.6666C131.328 38.6609 132.037 37.9082 133.123 37.9082C133.709 37.9082 134.043 38.1199 134.46 38.5374C135.838 39.9194 137.586 40.6663 140.05 40.6663C144.091 40.6663 146.513 38.2434 146.513 34.315V32.5566H146.431C144.93 34.6031 142.513 35.7793 139.381 35.7793C133.879 35.7793 130.876 32.5154 130.876 26.4522V17.5486C130.876 16.2959 131.586 15.5432 132.712 15.5432C133.838 15.5432 134.507 16.2959 134.507 17.5486V26.0758C134.507 30.216 136.507 32.386 140.261 32.386C144.097 32.386 146.513 29.7102 146.513 25.3231V17.5486C146.513 16.2959 147.223 15.5432 148.308 15.5432C149.47 15.5432 150.132 16.2959 150.132 17.5486Z"
        fill={fill}
      />
      <path
        d="M163.353 23.9058C169.987 23.9881 172.322 25.8288 172.322 29.969C172.322 33.9386 169.066 36.285 163.564 36.285C159.353 36.285 156.602 35.4499 154.637 33.6092C154.303 33.274 154.098 32.8153 154.098 32.3154C154.098 31.2686 154.807 30.5571 155.769 30.5571C156.227 30.5571 156.69 30.7688 157.101 31.0981C158.561 32.3507 160.731 33.0211 163.312 33.0211C166.691 33.0211 168.691 31.8508 168.691 29.8866C168.691 28.1282 167.4 27.4225 163.019 27.3755C156.678 27.3343 154.344 25.6171 154.344 21.6887C154.344 17.8014 157.722 15.2903 163.06 15.2903C166.48 15.2903 168.855 16.0019 170.773 17.5897C171.231 18.0073 171.442 18.466 171.442 19.0129C171.442 20.142 170.732 20.8536 169.735 20.8536C169.318 20.8536 168.943 20.7301 168.65 20.4772C167.066 19.2246 165.312 18.5953 163.312 18.5953C160.057 18.5953 157.975 19.8068 157.975 21.6887C157.975 23.2824 159.183 23.8646 163.353 23.9058Z"
        fill={fill}
      />
      <path
        d="M189.789 32.6448C188.622 35.0677 186.246 36.285 183.114 36.285C178.445 36.285 175.606 33.3152 175.606 28.34V10.9443C175.606 9.73286 176.316 8.9801 177.442 8.9801C178.568 8.9801 179.237 9.73286 179.237 10.9443V15.796H187.161C188.287 15.796 188.956 16.4665 188.956 17.5133C188.956 18.5601 188.287 19.1834 187.161 19.1834H179.237V28.4635C179.237 31.2628 180.738 32.8976 183.284 32.8976C184.997 32.8976 186.076 32.1449 186.915 30.8099C187.208 30.3924 187.625 30.0572 188.334 30.0572C189.296 30.0572 190.006 30.7688 190.006 31.8156C190 32.1037 189.912 32.3919 189.789 32.6448Z"
        fill={fill}
      />
      <path
        d="M28.2657 23.4765C28.1542 25.3054 27.468 27.399 24.0543 28.4693C18.3237 30.263 15.0684 34.2326 13.8249 35.4676C12.2823 37.0025 11.0681 39.1314 10.2176 42.0659C9.16186 45.6886 8.22338 47.0059 4.92699 47.0059C2.20541 47.0059 0 44.7947 0 42.0659C0 39.3372 1.62473 37.4965 4.92699 37.126C12.7339 36.2556 18.4117 31.0746 18.4117 23.6059V23.4059C18.4117 15.9372 12.7339 10.7561 4.92699 9.88576C1.62473 9.50938 0 7.66867 0 4.93994C0 2.21121 2.20541 0 4.92699 0C8.22338 0 9.75427 1.88188 10.2704 4.93994C10.6927 7.4452 12.2823 10.0034 13.8249 11.5383C15.0684 12.7792 18.3237 16.7429 24.0543 18.5365C27.468 19.601 28.1542 21.7005 28.2657 23.5235V23.4765Z"
        fill={fill}
      />
      <path
        d="M3.58382 28.4399H4.63373C7.27319 28.4399 9.58419 26.4463 9.74256 23.8058C9.91265 20.9418 7.64858 18.5601 4.82143 18.5601C4.82143 18.5601 3.74805 18.5307 3.44304 18.5601C2.32274 18.6659 1.49571 19.4657 1.34321 20.5654C1.13792 22.0651 1.13792 24.8703 1.34321 26.3052C1.51917 27.4696 2.3638 28.3341 3.58382 28.4399Z"
        fill={fill}
      />
    </svg>
  );
}

DemystLogo.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  id: PropTypes.string,
  width: PropTypes.number,
};
