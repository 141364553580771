import clsx from "clsx";
import { PropTypes } from "prop-types";
import { FiAlertTriangle, FiCheckCircle, FiInfo } from "react-icons/fi";

const Icon = ({ type }) => {
  switch (type) {
    case "success":
      return <FiCheckCircle />;

    case "info":
      return <FiInfo />;

    case "error":
    default: {
      return <FiAlertTriangle />;
    }
  }
};

export const MessageBox = ({ className, type = "error", showIcon = true, children }) => {
  return (
    <div className={clsx("MessageBox", `MessageBox__${type}`, className)}>
      {showIcon ? (
        <>
          <Icon type={type} />
          {children}
        </>
      ) : (
        children
      )}
    </div>
  );
};

MessageBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(["error", "info", "success"]),
};
