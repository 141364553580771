import Link from "next/link";
import { footerMenu } from "../../../../landingPage/datasets/footerMenu";
import React from "react";

export const AuthPageFooter = () => {
  let currentYear = new Date().getFullYear();
  return (
    <footer className="AuthPageFooter">
      <div className="AuthPageFooter__content">
        <span>© {currentYear} DemystData, Ltd</span>
        {footerMenu.columns
          .filter(c => c.header === "Legal")
          .map(c =>
            c.items
              .filter(l => !l.hideShort)
              .map(link => (
                <React.Fragment key={link.shortName}>
                  <div className="AuthPageFooter__content__spacer" />
                  <Link href={link.url} target={link.isExternal ? "_blank" : "_self"}>
                    {link.shortName}
                  </Link>
                </React.Fragment>
              )),
          )}
      </div>
    </footer>
  );
};
